.login-form {
  display: flex;
  justify-content: center;
  flex-direction: column;

  max-width: 500px;
  text-align: center;

  padding: 20px;
}

.login-form h3 {
  margin: 20px;
  font-size: 30px;
}

.login-form button {
  margin-top: 20px;
}
