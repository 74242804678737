.photo-container {
  min-height: 250px;
  min-width: 300px;
  max-height: 500px;
  max-width: 500px;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.photo-container > * {
  margin: 10px;
}

.photo-container label {
  margin-right: 4px;
}

.date {
  display: flex;
  flex-direction: row;
}

.date > div {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.img {
  min-height: 100px;
  min-width: 100px;
  max-height: 300px;
  max-width: 500px;
  padding: 10px;
}

.input-form {
  padding: 10px;
}

.input-form > *,
.date h4 {
  margin-bottom: 10px;
}

.date > :nth-child(1) {
  margin-right: 10px;
}
.before-after-row {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.before-after-label {
  font-size: 16px;
  margin-right: 20px;
}
