#header-form {
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 20px 10px;
}

#header-form > div {
  display: flex;
  justify-content: center;
  padding: 0 10px;
  /* flex: 0 0 50%; */
}

#header-form > div:nth-child(3) {
  justify-content: end;
}

main {
  width: 100%;

  /* display: flex;
  flex-direction: column; */
  /* justify-content: center; */
  /* align-items: center; */
  margin: auto;
  max-width: 1000px;
}
