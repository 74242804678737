.card {
  display: flex;
  flex-direction: row;
}

.date {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.cards {
  margin: 20px;
  max-width: 1000px;
}

.card h2 {
  margin-bottom: 10px;
}

.card h3 {
  margin: 10px 0 5px 0;
}

.card img {
  padding-top: 0;
}

.info-container {
  width: 100%;
}

.no-photo {
  height: 230px;
  width: 230px;
  background-color: lightgray;

  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 10px 10px 10px;
}

.btn-container {
  margin-top: 10px;
  float: right;
  display: flex;
  width: 100%;
}

.btn-container > :nth-child(2) {
  width: 80%;
}

.btn-container > :first-child {
  margin-right: 10px;
}

.fullness-row {
  display: flex;
  flex-direction: row;
}

.fullness-row > :first-child {
  margin-right: 10px;
}

.fullness-row span {
  font-weight: normal;
}

/* .info-container > button {

} */

/* .card-container {
  width: 100%;

  display: flex;
  justify-content: center;
} */
